import { LinkedIn } from "@mui/icons-material";
import { Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "../../components/Link";
import { DLBanner } from "../landing/DLBanner";
import { TEAM } from "./constants";

export default function Team() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box>
    <Box width={{ xs: "90%", md: "80%" }} m="auto" py={12} maxWidth={1400}>
      <Typography variant="h6" fontWeight={500}>Team</Typography>
      <Typography variant={md ? "h1" : "h2"} fontWeight={600}>Meet the minds</Typography>
      <Box m="auto" my={8}>
        <Grid container spacing={8} columns={{ xs: 6, md: 12 }}>
          {TEAM.map(({ avatar, name, designation, linkedIn }, idx) =>
            <Grid item xs={3} md={3} key={idx}>
              <Stack key={idx} justifyContent="center" textAlign="center">
                <img src={"/media/images/Team/" + name.toLowerCase().replaceAll(" ","-") + ".jpg"} width="100%" alt={name} style={{ borderRadius: "20%", aspectRatio: "1/1", marginBottom: "16px" }} />
                <Typography variant="h6" fontWeight={500}>
                  {name}
                </Typography>
                <Typography variant="body1" fontWeight={500} color="text.secondary">
                  {designation}
                </Typography>
              </Stack>
            </Grid>)}
        </Grid>
      </Box>
    </Box>
    <DLBanner />
  </Box>
}