import { Button, Stack } from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "../Link";

export const Navbar = ({ isMenuVisible,
  userHasScrolled,
  setIsMenuVisible, }) => {
  return <Box
  boxSizing="border-box"
    width={{ xs: "100%", md: "90%" }}
    mx="auto" pl={4} pr={1.5} py={1.5} bgcolor="#000000" maxWidth={1400} borderRadius={100} sx={{
      position: "sticky",
      top: "24px",
      zIndex: 99,
      bgcolor: "#000000",
      boxShadow: userHasScrolled && "0 0 50px rgb(0, 0, 0, 0.7)",
      "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))":
      {
        bgcolor: userHasScrolled && "rgb(0, 0, 0, 0.7)",
        backdropFilter: userHasScrolled && "blur(4em)",
      },
    }}>
    <Stack justifyContent="space-between" alignItems="center" direction="row">
      <Link path="/" component={
        <img src="/logos/logo+wordmark.svg" height={32} alt="alto" />
      } />
      {/* {md && <NavlinkGroup />} */}
 <Button color={userHasScrolled ? "white" : "softWhite"} size="medium" variant="contained">Coming soon</Button>
      {/* {md || (
        <IconButton sx={{ color: "white.main" }} onClick={() => setIsMenuVisible(!isMenuVisible)}>
          {isMenuVisible ? <CloseOutlined /> : <Menu />}
        </IconButton>
      )} */}
    </Stack>
  </Box >
}