import { Typography, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { FEATURES } from "../constants"
import { InView } from "react-intersection-observer";

export const TextView = ({ setCurrItemIdx }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Stack spacing={{ xs: 6, md: 0 }}>{FEATURES.map(({ title, desc, image }, idx) =>
    <InView
      key={idx}
      threshold={0.5}
      onChange={(inView) => (inView ? setCurrItemIdx(idx) : "")}
    >
      {({ ref, inView }) => {
        return <Stack justifyContent="center" key={idx} height={{ xs: "auto", md: "100vh" }}>
          <Stack ref={ref} spacing={2}>
            <Typography variant={md ? "h1" : "h2"} fontWeight={600}>{title}</Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">{desc}</Typography>
          </Stack>
          {!md && <img src={image} style={{
            width: "100%",
            height: "auto"
          }} alt="" />}
        </Stack>
      }}
    </InView>
  )}</Stack>
}