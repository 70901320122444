import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { ROADMAP } from "./constants";
import { InView } from "react-intersection-observer";
import { useState } from "react";

export default function Roadmap() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [seen, setSeen] = useState([]);
  return <Box id="roadmap">
    <Box width={{ xs: "85%", md: "80%" }} m="auto" py={12} maxWidth={1400}>
      <Typography textAlign="center" variant={md ? "h1" : "h2"} fontWeight={600}>Roadmap</Typography>
      <Box mt={12}>
        {ROADMAP.map(({ title, items }, idx) =>
          <InView
            key={idx}
            threshold={0.5}
            triggerOnce
            onChange={(inView, entry) => {
              if (inView) {
                if (!seen.includes(idx)) {
                  setSeen(seen => [...seen, idx])
                }
                // else
                //   setSeen(seen => seen.filter(t => t !== seen[seen.length - 1]))
              }
            }}
          >
            {({ ref, inView }) => {
              return <Grid key={idx} ref={ref} container columns={{ xs: 12 }}>
                {md && <Grid item xs alignSelf="center">
                  <Stack textAlign="right" mr={8} spacing={2} mb={24} className={inView ? "fade-up fade-up-animate" : "fade-up"} sx={{ visibility: idx % 2 === 0 ? "hidden" : "visible" }}>
                    <Typography variant={md ? "h3" : "h4"} fontWeight={600}>{title}</Typography>
                    <Stack spacing={4}>
                      {items.map(({ title, desc }, idx) =>
                        <Typography key={idx} variant="body1" fontWeight={500}>{title}: <Box display="inline" color="text.secondary">{desc}</Box></Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>}
                <Grid item xs="auto">
                  <Stack direction="column" mt={{ xs: 2.5, md: 3 }} className={seen.includes(idx) ? "build-in" : "build-out"} sx={{
                    transition: "height 2s 0.4s cubic-bezier(0.16, 1, 0.3, 1)"
                  }}>
                    <Box position="relative" width="5px" m="auto" flex={1} sx={{ background: idx !== ROADMAP.length - 1 ? "#9747FF" : "transparent", transitionDuration: "0.7s", }}>
                      <Box className={seen.includes(idx) ? "fade-in" : "fade-out"}>
                        <Box width={{ xs: 36, sm: 64, md: 80 }} borderRadius={100} bgcolor="#9747FF40" top={{ xs: -18, sm: -32, md: -40 }} sx={{ display: "grid", placeItems: "center", position: "absolute", left: "50%", transform: "translateX(-50%)", transition: "all ease 0.7s", aspectRatio: "1/1" }}>
                          <Box width={{ xs: 10, sm: 16, md: 24 }} borderRadius={100} bgcolor="#9747FF" sx={{ aspectRatio: "1/1" }}>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs alignSelf="center">
                  <Stack ml={{ xs: 4, sm: 6, md: 8 }} spacing={2} mb={24} className={inView ? "fade-up fade-up-animate" : "fade-up"} sx={{ visibility: md ? idx % 2 === 1 ? "hidden" : "visible" : "visible" }}>
                    <Typography variant={md ? "h3" : "h4"} fontWeight={600}>{title}</Typography>
                    <Stack spacing={4}>
                      {items.map(({ title, desc }, idx) =>
                        <Typography key={idx} variant="body1" fontWeight={500}>{title}: <Box display="inline" color="text.secondary">{desc}</Box></Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            }}
          </InView>)}
      </Box>
    </Box>
  </Box >
} 