export const FAQ_ITEMS = [
  {
    title: "What is FlexiBus?",
    desc: `FlexiBus is a real-time intercity bus tracking platform that allows you to track the location of buses on your route and plan your journey accordingly`,
  }, {
    title: "How does FlexiBus work?",
    desc: `FlexiBus uses GPS technology to track the location of buses in real-time. You can access this information through our website or mobile app and use it to plan your journey accordingly`,
  }, {
    title: "What are the benefits of using FlexiBus?",
    desc: `By using FlexiBus, you can plan your journey more efficiently, avoid waiting for buses at the bus stop, get real-time updates on delays or cancellations, and reduce the risk of missing your bus`,
  }, {
    title: "How do I track my bus on FlexiBus?",
    desc: `To track your bus on FlexiBus, visit our website or download our mobile app, enter your source and destination cities and travel date, select your bus operator and bus number, and view the real-time location of your bus on the map`,
  },{
    title: "What if I have a problem with using FlexiBus or need help?",
    desc: `If you have any issues with using FlexiBus or need help, you can contact our customer support team via phone, email, or live chat. We are available 24/7 to assist you`,
  },
];
