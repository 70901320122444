import { Box } from "@mui/material";
import { useState } from "react";
import { StatBlock } from "../../StatBlock";

export const ImageView = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  return <Box position="relative">
    <Box m="auto" width="90%">
      <img src="/media/images/screens.png"
        className={imgLoaded ? "visible" : "invisible"}
        onLoad={() => setImgLoaded(true)}
        width="100%"
        alt="" />
    </Box>
    <Box
      width="100%"
      bgcolor="black.main"
      className={imgLoaded ? "invisible" : "visible"}
      sx={{
        aspectRatio: "153/107",
        boxSizing: "border-box"
      }}
    />
    <Box width="100%" mt={-2}>
      <StatBlock />
    </Box>
  </Box>
}