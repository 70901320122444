import { Typography, Box, Stack, Button, useTheme, useMediaQuery } from "@mui/material"
import { ImageView } from "./ImageView"

export const Hero = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="100%">
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto" py={12} maxWidth={1400}>
      <div data-sal="fade-up">
        <Stack alignItems={{ xs: "start", md: "center" }} textAlign={{ xs: "left", md: "center" }} spacing={2}>
          <Typography variant={md ? "h1" : "h2"} fontWeight={600} maxWidth={900}>
            Goa's first inter-city bus tracker app
          </Typography>
          <Typography width={{ xs: "90%", md: "50%" }} variant="body2" color="text.secondary" fontWeight={500}>
            A transport network startup aiming to provide easy & efficient flow of transit of passengers travelling via local transport
          </Typography>
          <Box py={4}>
            <Button color="white" size="large" variant="contained">Coming soon</Button>
          </Box>
        </Stack>
      </div>
    </Box>
    <ImageView />
  </Box>
}