import { Box, Grid, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { ImageView } from "./ImageView"
import { TextView } from "./TextView";

export const Features = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [currItemIdx, setCurrItemIdx] = useState(0);
  return <Box width="100%" pb={24}>
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto"
    >
      <Grid container spacing={6} columns={{ md: 12 }}>
        <Grid item md={6}>
          {md && <ImageView currItemIdx={currItemIdx} />}
        </Grid>
        <Grid item md={6}>
        <TextView setCurrItemIdx={setCurrItemIdx} />
        </Grid>
      </Grid>
    </Box>
  </Box>
}