import { ThemeProvider } from "@mui/material"
import { Landing } from "./pages/landing";
import theme from './theme';
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "./layout";
import sal from "sal.js";
import "sal.js/dist/sal.css";
import Team from "./pages/team";
import Roadmap from "./pages/roadmap";
import Privacy from "./pages/privacy";
import { Contact } from "./pages/contact";

function App() {
  const { pathname } = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      window.scrollY === 0
        ? setUserHasScrolled(false)
        : setUserHasScrolled(true);

    };
    if (isMenuVisible) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isMenuVisible]);

  useEffect(() => {
    sal({ threshold: 0.3,  });
  }, [pathname]);

  return <ThemeProvider theme={theme}>
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            isMenuVisible={isMenuVisible}
            setIsMenuVisible={setIsMenuVisible}
            userHasScrolled={userHasScrolled}
          />
        }
      >
        <Route index element={<Landing />} />
        <Route path="team" element={<Team />} />
        <Route path="roadmap" element={<Roadmap />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
  </ThemeProvider>;
}

export default App;
