import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system"

export const DLBanner = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="100%" py={12} sx={{ background: "linear-gradient(123.24deg, #6746F8 0%, #BB72F9 100%)" }}>
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto"
    >
      <Stack spacing={2} justifyContent="center" alignItems="center" textAlign="center" maxWidth={800} m="auto">
        <img src="/logos/logo-white.svg" height={80} alt="" />
        <Typography variant='body1' fontWeight={500}>Tired of waiting in the sun & rain?</Typography>
        <Typography variant={md ? "h1" : "h2"} fontWeight={600}>Save time, start using Flexibus today!</Typography>
        <Typography variant='body1' fontWeight={500} color="text.secondary" maxWidth={600}>We're revolutionizing the way you travel and aim to transform your daily transit into a seamless & enjoyable experience</Typography>
        <Box py={4}>
          <Button color="white" size="large" variant="contained" sx={{ color: "#8B59F9" }}>Try the app now</Button>
        </Box>
      </Stack>
    </Box>
  </Box>
}