export const FEATURES = [
  {
    image: "/media/images/Features/1.png",
    title: "Detect the closest buses",
    desc: "Check availability, ETA, book online, bus info, scan to pay & much more!"
  },
  {
    image: "/media/images/Features/2.png",
    title: "Check seat Availability",
    desc: "Check seat availability before you hop on! No more crowded buses"
  },
  {
    image: "/media/images/Features/3.png",
    title: "Securely scan to pay",
    desc: "Scan the QR code in front of your seat & pay instantly - hassle free"
  },
  {
    image: "/media/images/Features/4.png",
    title: "Bus arrival reminder",
    desc: "Never miss a bus or your stop with bus arrival reminders"
  },
]