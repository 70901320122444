export const ROADMAP = [
  {
    title: "Quarter 1 (Q1) 2023",
    items: [
      {
        title: "App design and development",
        desc: `The focus in Q1
        will be on finalizing the design of the app and
        developing its core features, such as bus
        tracking and arrival times. The design will be
        user friendly, intuitive, and visually appealing to
        cater to the needs of the target customer base.`
      },
      {
        title: "Geo mapping",
        desc: `In Q1, the apps geo mapping
        features will be developed, allowing users to
        view the bus routes and stops on a map and
        track the buses in real time. The app will also
        display the current capacity of the bus,
        enabling users to make informed travel
        decisions.`
      },
    ]
  },
  {
    title: "Quarter 2 (Q2) 2023",
    items: [
      {
        title: "Connecting the backend to government servers",
        desc: `In Q2, the apps backend will be connected to the 
        government servers responsible for managing the 
        public transportation system in Goa. This will 
        enable the app to provide users with accurate 
        and up to date information on bus schedules 
        and routes.`
      },
      {
        title: "Adding payment gateways",
        desc: `In Q2, the app will 
        integrate payment gateways, enabling users to 
        pay for their bus fares and other in app 
        purchases using their mobile devices. The app will 
        support multiple payment methods, including 
        debit/credit cards, digital wallets, and UPI`
      },
    ]
  },
  {
    title: "Quarter 3 (Q3) 2023",
    items: [
      {
        title: "Quality assurance testing",
        desc: `In Q3, the app will 
        undergo extensive quality assurance testing to 
        ensure that it functions smoothly and 
        seamlessly. This includes testing for 
        compatibility across various devices and 
        operating systems and ensuring data security.`
      },
      {
        title: "Launch of the app",
        desc: `In Q3, the app will be 
        launched to the public, with a focus on building 
        brand awareness and increasing user 
        acquisition. The app will be promoted through 
        various channels, including social media, online 
        advertising, and partnerships with local 
        businesses and tour operators.`
      },
    ]
  },
  {
    title: "Quarter 4 (Q4) 2023",
    items: [
      {
        title: "Growth and expansion",
        desc: `In Q4, the app will 
        receive user feedback, and necessary 
        improvements and updates will be made to 
        enhance the user experience further. These 
        updates may include new features and 
        functionalities, UI/UX changes, bug fixes, and 
        optimisations for speed and performance.`
      },
      {
        title: "User feedback and updates",
        desc: ` In Q4, the focus will be 
        on growing the user base and expanding the 
        apps reach to other regions. This will be 
        achieved through targeted marketing efforts, 
        strategic partnerships, and community 
        engagement initiatives. The app will also 
        explore new revenue streams and business 
        opportunities to increase its sustainability and 
        profitability.`
      },
    ]
  },
]