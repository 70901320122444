export const TEAM = [
  {
    avatar: "/media/images/Team/isaac-fernandes.jfif",
    name: "Isaac Fernandes",
    designation: "Founder, CEO",
    linkedIn: "https://www.linkedin.com/in/isaacfernandes2050/",
  },
  {
    avatar: "/media/images/Team/aryan-raul.jfif",
    name: "Aryan Raul",
    designation: "Co-Founder",
    linkedIn: "https://www.linkedin.com/in/aryan-raul-23a7a6227/",
  },
  {
    avatar: "/media/images/Team/dhruv-chawla.jfif",
    name: "Dhruv Chawla",
    designation: "COO",
    linkedIn: "https://www.linkedin.com/in/dhruvchawla23/",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Nikhil Rajani",
    designation: "Lead Strategist",
    linkedIn: "https://www.linkedin.com/in/nikhil-bharat-rajani",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Ranjeet Sonawane",
    designation: "Financial consultant",
    linkedIn: "https://www.linkedin.com/in/ranjeetsonawane",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Natasha Fernandes",
    designation: "Head of strategy",
    linkedIn: "https://www.linkedin.com/in/natasha-fernandes-0869b322b",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Charu Saraswat",
    designation: "Backend engineer",
    linkedIn: "https://www.linkedin.com/in/charu-saraswat",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Dhruv Kumar Jha",
    designation: "Cloud Developer",
    linkedIn: "https://www.linkedin.com/in/mr-dhruv-kumar-jha",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Dhruv Pai Angle",
    designation: "Cheif of Marketing",
    linkedIn: "https://www.linkedin.com/in/dhruvapaiangle",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Shlok Bhosale",
    designation: "Frontend Developer",
    linkedIn: "https://www.linkedin.com/in/shlok-bhosale",
  },
  {
    avatar: "/media/images/Team/nikhil-rajani.jpg",
    name: "Anuja Goyal",
    designation: "Systems Engineer",
    linkedIn: "https://www.linkedin.com/in/shlok-bhosale",
  },
]