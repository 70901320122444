import { Box, Button, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { DLBanner } from "../landing/DLBanner";

export const Contact = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box>
    <Box width={{ xs: "90%", md: "80%" }} m="auto" py={12} maxWidth={1400}>
      <Typography variant="h6" fontWeight={500}>Contact</Typography>
      <Typography variant={md ? "h1" : "h2"} fontWeight={600}>Get in touch</Typography>
      <Box my={8} maxWidth={900}>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <Stack
            spacing={4}
            borderRadius={2}
            width="100%"
          >
            <Stack direction="row" spacing={2}>
              <TextField
                sx={{ width: "100%" }}
                label="First Name"
                name="first-name"
                variant="outlined"
                required
              />
              <TextField
                sx={{ width: "100%" }}
                label="Last Name"
                name="last-name"
                variant="outlined"
                required
              />
            </Stack>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              required
            />
            <TextField
              label="Message (Optional)"
              name="message"
              rows={4}
              variant="outlined"
              multiline
            />
            <Button
              type="submit"
              variant="contained"
              color="white"
              size="large"
              sx={{ width: "fit-content" }}
            >
              Send message
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
    <DLBanner />
  </Box>
}